@tailwind base;
@tailwind components;
@tailwind utilities;

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html,body{
  scroll-behavior: smooth;
}
@font-face {
    font-family: Heandsean;
    src: url("../public/fonts/handsean.woff");
}
.navbar_bg,footer{
  background-image: linear-gradient(to right,#010914,#041324,#05233c,#063454,#084265);
}
.logo{
    font-family: "Heandsean",sans-serif;
    text-align: center;
}
.header{
    background-image: url("../public/images/header.png");
    min-height: 650px;
    background-size: cover;
    background-position: center;
}
.navbar_link::before{
  content: '';
  position: absolute;
  bottom: -2px; /* Adjust the position of the line as needed */
  left: 0;
  width: 0;
  height: 2px; /* Adjust the line thickness as needed */
  background-color: #e0ae77;
  border-radius: 20px;
  transition: all 0.3s; /* Add a smooth transition for the line */
}
.navbar_link:hover::before{
  width: 100%;
}
.mobile-menu {
  background-image: linear-gradient(to right,#010914,#041324,#05233c,#063454,#084265);
    overflow: hidden;
  }
  
  /* Add styles to expand the mobile menu when it's open */
  .mobile-menu.open {
    /* max-height: 100vh; */
    max-height: fit-content;
  }
  .article_paragraphs p{
    padding-top: 1rem;
  }
  .bio_paragraphs p{
    padding-top: 1rem;
  }
  .nocopy_paste{
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }